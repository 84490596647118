import { DateTime } from 'luxon'
import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

const SampleDataLabel = styled.label`
  font-size: 1.5em;
  width: 35%;
  margin-right: 10px;
`

function FormBankingAccountIssuance(props) {
  const [bankingAccountDetails, setBankingAccountDetails] = useState({})
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const schemas = useSelector((state) => state.settings.schemas)
  const connections = contactsState.contactSelected.Connections

  const bankingAccountForm = useRef(null)

  const { sendRequest, closeBankingAccountModal, bankingAccountModalIsOpen } = props

  const handleClosingModal = () => {
    dispatch(setInvitationURL(''))
    dispatch(setInvitationSelected({}))

    closeBankingAccountModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(bankingAccountForm.current)

    const bankingAccountAttr = [
      {
        name: 'account_id',
        value: form.get('account_id'),
      },
      {
        name: 'account_established_date',
        value: form.get('account_established_date') ? Math.floor(
            DateTime.fromISO(form.get('account_established_date')).ts / 1000
          ).toString() : '',
      },
      {
        name: 'username',
        value: form.get('username'),
      },
      {
        name: 'institution_id',
        value: form.get('institution_id'),
      },
      {
        name: 'institution_name',
        value: form.get('institution_name'),
      },
      {
        name: 'surnames',
        value: form.get('surnames'),
      },
      {
        name: 'given_names',
        value: form.get('given_names'),
      },
      {
        name: 'date_of_birth',
        value: form.get('date_of_birth') ? Math.floor(
            DateTime.fromISO(form.get('date_of_birth')).ts / 1000
          ).toString() : '',
      },
      {
        name: 'email',
        value: form.get('email'),
      },
      {
        name: 'phone_number',
        value: form.get('phone_number'),
      },
      {
        name: 'account_access_type',
        value: form.get('account_access_type'),
      },
      {
        name: 'credential_issued_date',
        value: form.get('credential_issued_date') ? Math.floor(
            DateTime.fromISO(form.get('credential_issued_date')).ts / 1000
          ).toString() : '',
      },
    ]

    const schema = schemas.SCHEMA_BANKING_ACCOUNT
    const schemaParts = schema.split(':')

    sendRequest('CREDENTIALS', 'ISSUE_TO_CONNECTIONS', {
      connections: connections,
      schemaID: schema,
      schemaVersion: schemaParts[3],
      schemaName: schemaParts[2],
      schemaIssuerDID: schemaParts[0],
      attributes: bankingAccountAttr,
    })

    handleClosingModal()
  }

  const selectOption = (option) => {
    switch (option) {
      case 'option_one':
        setBankingAccountDetails({
            account_id: '38821',
            account_established_date: '2022-04-19',
            username: 'johnsmith',
            institution_id: '481231',
            institution_name: 'Mountain Banking',
            surnames: 'Smith',
            given_names: 'John',
            date_of_birth: '1998-12-08',
            email: 'johnsmith@sampledomain.com',
            phone_number: '801-882-8421',
            account_access_type: 'Individual',
            credential_issued_date: '2020-02-01',
          })

        break

      case 'option_two':
        setBankingAccountDetails({
            account_id: '551231',
            account_established_date: '2019-09-18',
            username: 'sarahconnor',
            institution_id: '481231',
            institution_name: 'Mountain Banking',
            surnames: 'Connor',
            given_names: 'Sarah',
            date_of_birth: '1983-03-25',
            email: 'sarah_connor@sampledomain.com',
            phone_number: '801-443-8891',
            account_access_type: 'Parent/Guardian',
            credential_issued_date: '2022-07-05',
          })

        break
    
      case 'option_three':
        setBankingAccountDetails({
            account_id: '8831231',
            account_established_date: '2010-10-10',
            username: 'landonjohnson',
            institution_id: '481231',
            institution_name: 'Mountain Banking',
            surnames: 'Johnson',
            given_names: 'Landon',
            date_of_birth: '2000-01-22',
            email: 'landonjohnson@sampledomain.com',
            phone_number: '801-901-9210',
            account_access_type: 'Power of Attorney',
            credential_issued_date: '2018-05-24',
          })

        break

      default:
        setBankingAccountDetails({
          account_id: '',
          account_established_date: '',
          username: '',
          institution_id: '',
          institution_name: '',
          surnames: '',
          given_names: '',
          date_of_birth: '',
          email: '',
          phone_number: '',
          account_access_type: '',
          credential_issued_date: '',
        })
        break
    }
  }

  return (
    <StyledPopup
      open={bankingAccountModalIsOpen}
      closeOnDocumentClick
      onClose={closeBankingAccountModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue Banking Account Credential</ModalHeader>
        <ModalContentWrapper>
            <ModalContent>
              <InputBox style={{ marginBottom: '25px' }}>
                <SampleDataLabel>Banking Account Credential Sample Sets</SampleDataLabel>
                <select
                  onChange={(e) => selectOption(e.target.value)}
                  className="dropdown-content"
                >
                  <option value="default">Select Option:</option>
                  <option value="option_one">Option 1</option>
                  <option value="option_two">Option 2</option>
                  <option value="option_three">Option 3</option>
                </select>
            </InputBox>
              <form onSubmit={handleSubmit} ref={bankingAccountForm}>
                <InputBox>
                  <ModalLabel htmlFor="account_id">
                    Account ID
                  </ModalLabel>
                  <InputFieldModal
                    name="account_id"
                    id="account_id"
                    placeholder=""
                    defaultValue={bankingAccountDetails.account_id}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="account_established_date">
                    Account Established Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="account_established_date"
                    id="account_established_date"
                    placeholder=""
                    defaultValue={bankingAccountDetails.account_established_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="username">
                    Username
                  </ModalLabel>
                  <InputFieldModal
                    name="username"
                    id="username"
                    placeholder=""
                    defaultValue={bankingAccountDetails.username}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="institution_id">
                    Institution ID
                  </ModalLabel>
                  <InputFieldModal
                    name="institution_id"
                    id="institution_id"
                    placeholder=""
                    defaultValue={bankingAccountDetails.institution_id}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="institution_name">
                    Institution Name
                  </ModalLabel>
                  <InputFieldModal
                    name="institution_name"
                    id="institution_name"
                    placeholder=""
                    defaultValue={bankingAccountDetails.institution_name}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="surnames">
                    Surnames
                  </ModalLabel>
                  <InputFieldModal
                    name="surnames"
                    id="surnames"
                    placeholder=""
                    defaultValue={bankingAccountDetails.surnames}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="given_names">
                    Given Names
                  </ModalLabel>
                  <InputFieldModal
                    name="given_names"
                    id="given_names"
                    placeholder=""
                    defaultValue={bankingAccountDetails.given_names}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="date_of_birth">
                    Date of Birth
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="date_of_birth"
                    id="date_of_birth"
                    placeholder=""
                    defaultValue={bankingAccountDetails.date_of_birth}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="email">
                    Email
                  </ModalLabel>
                  <InputFieldModal
                    name="email"
                    id="email"
                    placeholder=""
                    defaultValue={bankingAccountDetails.email}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="phone_number">
                    Phone Number
                  </ModalLabel>
                  <InputFieldModal
                    name="phone_number"
                    id="phone_number"
                    placeholder=""
                    defaultValue={bankingAccountDetails.phone_number}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="account_access_type">
                    Account Access Type
                  </ModalLabel>
                  <InputFieldModal
                    name="account_access_type"
                    id="account_access_type"
                    placeholder=""
                    defaultValue={bankingAccountDetails.account_access_type}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="credential_issued_date">
                    Credential Issued Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="credential_issued_date"
                    id="credential_issued_date"
                    placeholder=""
                    defaultValue={bankingAccountDetails.credential_issued_date}
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormBankingAccountIssuance
