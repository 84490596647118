import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { CanUser, check } from './CanUser'

import FormInvitation from './FormInvitation'
import PageHeader from './PageHeader'
import PaginationSection from './PaginationSection'

import { setWaitingForInvitations } from '../redux/invitationsReducer'

import { ActionButton } from './CommonStylesForms'
import { LoadingHolder, Spinner } from './LoadingStyles'
export const JumpToPage = styled.input`
  margin: auto 10px auto 0;
  width: 60px;
  font-size: 1em;
  color: ${(props) => props.theme.primary_color};
`

function Invitations(props) {
  const dispatch = useDispatch()
  const invitationsState = useSelector((state) => state.invitations)
  const websocketsState = useSelector((state) => state.websockets)
  const { invitations, waitingForInvitations } = invitationsState
  const { websocket, readyForWebsocketMessages } = websocketsState

  const [createInvModalIsOpen, setCreateInvModalIsOpen] = useState(false)
  const closeCreateInvModal = () => setCreateInvModalIsOpen(false)

  const { sendRequest } = props

  useEffect(() => {
    if (check('invitations:read') && websocket && readyForWebsocketMessages) {
      sendRequest('INVITATIONS', 'GET_ALL', {
        params: {
          sort: [['updated_at', 'DESC']],
          pageSize: '10',
        },
        additional_tables: [],
      })

      dispatch(setWaitingForInvitations(true))
    }
  }, [websocket, readyForWebsocketMessages])

  return (
    <>
      {!waitingForInvitations ? (
        <div id="invitations">
          <PageHeader title={'Invitations'} />
          <PaginationSection
            history={props.history}
            sendRequest={sendRequest}
            paginationData={invitations}
            paginationFocus={'INVITATIONS'}
          />
          <CanUser
            perform="invitations:create"
            yes={() => (
              <ActionButton
                title="Add a New Invitation"
                onClick={() => {
                  setCreateInvModalIsOpen((o) => !o)
                }}
              >
                +
              </ActionButton>
            )}
          />
          <FormInvitation
            createInvModalIsOpen={createInvModalIsOpen}
            closeCreateInvModal={closeCreateInvModal}
            sendRequest={sendRequest}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching invitations, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Invitations
