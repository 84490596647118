import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { check } from './CanUser'

import PageHeader from './PageHeader'
import PaginationSection from './PaginationSection'

import {
  setWaitingForContacts,
  setWaitingForPendingConnections,
} from '../redux/contactsReducer'

import { LoadingHolder, Spinner } from './LoadingStyles'

function Contacts(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const websocketsState = useSelector((state) => state.websockets)

  const {
    contacts,
    pendingConnections,
    waitingForContacts,
    waitingForPendingConnections,
  } = contactsState
  const { websocket, readyForWebsocketMessages } = websocketsState

  const { sendRequest } = props

  useEffect(() => {
    if (check('contacts:read') && websocket && readyForWebsocketMessages) {
      sendRequest('CONTACTS', 'GET_ALL', {
        params: {
          sort: [['updated_at', 'DESC']],
          pageSize: '6',
        },
        additional_tables: [],
      })

      sendRequest('CONNECTIONS', 'PENDING_CONNECTIONS', {
        params: {
          sort: [['updated_at', 'DESC']],
          pageSize: '6',
        },
      })

      dispatch(setWaitingForContacts(true))
      dispatch(setWaitingForPendingConnections(true))
    }
  }, [websocket, readyForWebsocketMessages])

  return (
    <>
      {!waitingForContacts && !waitingForPendingConnections ? (
        <div id="contacts">
          <PageHeader title={'Contacts'} />
          <PaginationSection
            history={props.history}
            sendRequest={sendRequest}
            paginationData={contacts}
            paginationFocus={'CONTACTS'}
          />
          <PaginationSection
            history={props.history}
            sendRequest={sendRequest}
            paginationData={pendingConnections}
            paginationFocus={'PENDING_CONNECTIONS'}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching contacts, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Contacts
