import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  FormContainer,
  InputBox,
  LogoHolder,
  Logo,
  Form,
  Label,
  SubmitBtn,
  InputField,
} from './CommonStylesForms'

function PasswordReset(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const logo = settingsState.logo

  const [id, setId] = useState(undefined)

  const token = window.location.hash.substring(1)
  useEffect(() => {
    // Kick the user off this page if trying to access without a token
    if (!token) {
      console.log('no token')
      return
    }

    const decoded = jwt_decode(token)
    // Check if the token is expired
    if (Date.now() >= decoded.exp * 1000) {
      console.log('The link has expired')
      dispatch(
        setNotificationState({
          message: "The user doesn't exist or the link has expired",
          type: 'error',
        })
      )
      props.history.push('/admin')
      return
    } else {
      console.log('The token is valid')
      setId(decoded.id)
    }

    // Check the token on the back end
    Axios({
      method: 'POST',
      data: {
        token: token,
      },
      url: '/api/user/token/validate',
    }).then((res) => {
      if (res.data.error) {
        dispatch(
          setNotificationState({ message: res.data.error, type: 'error' })
        )
        props.history.push('/admin')
      }
    })
  }, [])

  const resetForm = useRef()
  const pass1 = useRef()
  const pass2 = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(resetForm.current)

    // Check the password match
    if (pass1.current.value !== pass2.current.value) {
      console.log("Passwords don't match")
      dispatch(
        setNotificationState({
          message: 'Passwords must match. Try again.',
          type: 'error',
        })
      )
    } else {
      // Update the DB, show the notification and redirect to the login view
      Axios({
        method: 'POST',
        data: {
          id: id,
          password: form.get('newPass'),
          token: token,
        },
        url: '/api/user/password/update',
      }).then((res) => {
        if (res.data.status) {
          dispatch(
            setNotificationState({ message: res.data.status, type: 'notice' })
          )
          props.history.push('/admin')
        } else if (res.data.error)
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        else {
          dispatch(
            setNotificationState({
              message: "Password couldn't be reset. Try again.",
              type: 'error',
            })
          )
        }
      })
    }
  }

  return (
    <FormContainer>
      <LogoHolder>
        {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
      </LogoHolder>
      <Form id="form" onSubmit={handleSubmit} ref={resetForm}>
        <InputBox>
          <Label htmlFor="newPass">New Password</Label>
          <InputField
            type="password"
            name="newPass"
            id="newPass"
            ref={pass1}
            required
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="confirmedPass">Confirm Password</Label>
          <InputField
            type="password"
            name="confirmedPass"
            id="confirmedPass"
            ref={pass2}
            required
          />
        </InputBox>
        <SubmitBtn type="submit">Submit</SubmitBtn>
      </Form>
    </FormContainer>
  )
}
export default PasswordReset
