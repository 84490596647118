import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import { CanUser, check } from './CanUser'

import FormInvitationDelete from './FormInvitationDelete'
import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import ReactDOMServer from 'react-dom/server'
import QRCode from 'qrcode.react'

import { Button } from './CommonStylesForms'
import { QRHolder } from './CommonStylesForms'
import { AttributeTable, AttributeRow } from './CommonStylesTables'
import { LoadingHolder, Spinner } from './LoadingStyles'
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 10px;
  width: 300px;
`

function Invitation(props) {
  const invitationsState = useSelector((state) => state.invitations)
  const websocketsState = useSelector((state) => state.websockets)
  const invitationSelected = invitationsState.invitationSelected
  const { websocket, readyForWebsocketMessages } = websocketsState

  const [waitingForInvitation, setWaitingForInvitation] = useState(true)
  const [
    deleteInvitationModalIsOpen,
    setDeleteInvitationModalIsOpen,
  ] = useState(false)

  const closeDeleteModal = () => setDeleteInvitationModalIsOpen(false)
  const deleteInvitation = () => {
    setDeleteInvitationModalIsOpen(true)
  }

  const { sendRequest, invitationId } = props

  useEffect(() => {
    //(AmmonBurgi) Stop waiting for invitationSelected if the invitation_id matches the target ID. If no match is found, fetch the needed invitation.
    if (websocket && readyForWebsocketMessages) {
      if (
        Object.keys(invitationSelected).length !== 0 &&
        invitationSelected.invitation_id === parseInt(invitationId)
      ) {
        const timestamp = DateTime.now().toMillis()
        const startTimestamp = invitationSelected.active_starting_at
          ? DateTime.fromISO(invitationSelected.active_starting_at, {
              zone: 'utc',
            }).ts
          : undefined
        const endTimestamp = invitationSelected.active_ending_at
          ? DateTime.fromISO(invitationSelected.active_ending_at, {
              zone: 'utc',
            }).ts
          : undefined

        if (
          invitationSelected.workflow_status === 'active' &&
          (timestamp < startTimestamp || timestamp > endTimestamp)
        ) {
          //(AmmonBurgi) When the starting time and ending time indicates that the invitation should be inactive, update it to be inactive.

          sendRequest('INVITATIONS', 'UPDATE_STATUS', {
            invitation_id: invitationId,
            workflow_status: 'inactive',
          })
        } else {
          setWaitingForInvitation(false)
        }
      } else {
        setWaitingForInvitation(true)
        if (check('invitations:read')) {
          sendRequest('INVITATIONS', 'GET', {
            invitation_id: invitationId,
          })
        }
      }
    }
  }, [invitationSelected, invitationId, websocket, readyForWebsocketMessages])

  return (
    <>
      {!waitingForInvitation ? (
        <div id="contact">
          <PageHeader
            title={'Invitation Details: ' + (invitationSelected.label || '')}
          />
          <PageSection>
            <h2>General Information</h2>
            <QRHolder style={{ float: 'right', textAlign: 'center' }}>
              {invitationSelected.invitation_url ? (
                invitationSelected.workflow_status === 'active' ? (
                  <>
                    <QR
                      value={invitationSelected.invitation_url}
                      size={256}
                      renderAs="svg"
                    />
                    <a
                      href={
                        'data:image/svg+xml;charset=utf-8,' +
                        encodeURIComponent(
                          ReactDOMServer.renderToString(
                            <QR
                              value={invitationSelected.invitation_url}
                              size={256}
                              renderAs="svg"
                              xmlns="http://www.w3.org/2000/svg"
                            />
                          )
                        )
                      }
                      download="qrcode.svg"
                      href-lang="image/svg+xml"
                    >
                      Download
                    </a>
                  </>
                ) : null
              ) : (
                <p>Loading...</p>
              )}
            </QRHolder>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Invitation ID:</th>
                  <td>{invitationSelected.invitation_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Contact ID:</th>
                  <td>{invitationSelected.contact_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Connection ID:</th>
                  <td>{invitationSelected.connection_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Invitation Message ID:</th>
                  <td>{invitationSelected.invitation_msg_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Invitation Key:</th>
                  <td>{invitationSelected.invitation_key || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Invitation Mode:</th>
                  <td>{invitationSelected.invitation_mode || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Accept:</th>
                  <td>{invitationSelected.accept || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Their Role:</th>
                  <td>{invitationSelected.their_role || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Their Label:</th>
                  <td>{invitationSelected.their_label || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Service Endpoint:</th>
                  <td>{invitationSelected.service_endpoint || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Domain:</th>
                  <td>{invitationSelected.domain || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Path:</th>
                  <td>{invitationSelected.path || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Workflow Status:</th>
                  <td>{invitationSelected.workflow_status || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Invitation State:</th>
                  <td>{invitationSelected.state || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Description:</th>
                  <td>{invitationSelected.description || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Active Starting Time:</th>
                  <td>
                    {invitationSelected.active_starting_at
                      ? new Date(
                          invitationSelected.active_starting_at
                        ).toLocaleString()
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Active Ending Time:</th>
                  <td>
                    {invitationSelected.active_ending_at
                      ? new Date(
                          invitationSelected.active_ending_at
                        ).toLocaleString()
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Uses Allowed:</th>
                  <td>{invitationSelected.uses_allowed || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Uses Total:</th>
                  <td>
                    {invitationSelected.uses_total ||
                    invitationSelected.uses_total === 0
                      ? invitationSelected.uses_total
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Created At:</th>
                  <td>
                    {invitationSelected.created_at
                      ? new Date(invitationSelected.created_at).toLocaleString()
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Updated At:</th>
                  <td>
                    {invitationSelected.updated_at
                      ? new Date(invitationSelected.updated_at).toLocaleString()
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>My DID:</th>
                  <td>{invitationSelected.my_did || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Invitation URL:</th>
                  <td
                    style={{
                      overflowWrap: 'break-word',
                      wordWrap: 'break-word',
                      maxWidth: '400px',
                      textDecoration:
                        invitationSelected.workflow_status !== 'active'
                          ? 'line-through'
                          : 'initial',
                    }}
                  >
                    {invitationSelected.invitation_url || ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            <CanUser
              perform="invitations:delete"
              yes={() => (
                <Button
                  title="Delete Invitation"
                  onClick={() => {
                    deleteInvitation(invitationSelected.invitation_id)
                  }}
                >
                  Remove
                </Button>
              )}
            />
          </PageSection>

          <FormInvitationDelete
            sendRequest={sendRequest}
            history={props.history}
            invitationId={invitationId}
            deleteInvitationModalIsOpen={deleteInvitationModalIsOpen}
            closeDeleteModal={closeDeleteModal}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching invitation, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Invitation
