import Axios from 'axios'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  StyledPopup,
  InputBox,
  Modal,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

function FormInvitationEmail(props) {
  const dispatch = useDispatch()
  const emailForm = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(emailForm.current)

    Axios({
      method: 'POST',
      data: {
        email: form.get('email'),
      },
      url: '/api/email/verify',
    }).then((res) => {
      if (res.data.error) {
        dispatch(
          setNotificationState({ message: res.data.error, type: 'error' })
        )
      } else {
        dispatch(
          setNotificationState({
            message: 'Email Invitation has been sent!',
            type: 'notice',
          })
        )
      }
    })

    props.closeEmailModal()
  }

  function closeEmailModal() {
    props.closeEmailModal()
  }

  return (
    <StyledPopup
      open={props.emailModalIsOpen}
      closeOnDocumentClick
      onClose={closeEmailModal}
    >
      <Modal className="modal">
        <ModalHeader>Send Email Invitation</ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form onSubmit={handleSubmit} ref={emailForm}>
              <InputBox>
                <ModalLabel htmlFor="email">Email Address</ModalLabel>
                <InputFieldModal
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@test.com"
                />
              </InputBox>
              <Actions>
                <CancelBtn type="button" onClick={closeEmailModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit">Send</SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeEmailModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormInvitationEmail
