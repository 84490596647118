import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  FormContainer,
  InputBox,
  LogoHolder,
  Logo,
  Form,
  Label,
  SubmitBtn,
  InputField,
} from './CommonStylesForms'

function AccountSetup(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const logo = settingsState.logo

  const [id, setId] = useState({})

  const token = window.location.hash.substring(1)
  useEffect(() => {
    // Kick the user off this page if trying to access without a token
    if (!token) {
      console.log('No token')
      return
    }

    const decoded = jwt_decode(token)
    // Check if the token is expired
    if (Date.now() >= decoded.exp * 1000) {
      console.log('The link has expired')
      dispatch(
        setNotificationState({
          message: "The user doesn't exist or the link has expired",
          type: 'error',
        })
      )
      props.history.push('/admin/login')
    } else {
      console.log('The token is valid')
      setId(decoded.id)
    }

    // Check the token on the back end
    Axios({
      method: 'POST',
      data: {
        token: token,
      },
      url: '/api/user/token/validate',
    }).then((res) => {
      if (res.data.error) {
        dispatch(
          setNotificationState({ message: res.data.error, type: 'error' })
        )
        props.history.push('/admin/login')
      }
    })
  }, [])

  const accSetupForm = useRef()
  const pass1 = useRef()
  const pass2 = useRef()
  const username = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(accSetupForm.current)

    // Check the password match
    if (pass1.current.value !== pass2.current.value) {
      console.log("Passwords don't match")
      dispatch(
        setNotificationState({
          message: 'Passwords do not match. Please try again',
          type: 'error',
        })
      )
    } else if (pass1.current.value === username.current.value) {
      console.log('Password should not match username')
      dispatch(
        setNotificationState({
          message: 'Password should not match username. Please try again',
          type: 'error',
        })
      )
    } else {
      // Update the user, redirect to login and setup notification
      Axios({
        method: 'POST',
        data: {
          id: id,
          username: form.get('username'),
          email: form.get('email'),
          password: form.get('password'),
          token: token,
          flag: 'set-up user',
        },
        url: '/api/user/update',
      }).then((res) => {
        if (res.data.status) {
          dispatch(
            setNotificationState({ message: res.data.status, type: 'notice' })
          )
          props.history.push('/admin')
        } else if (res.data.error) {
          dispatch(
            setNotificationState({ message: res.data.error, type: 'error' })
          )
        } else {
          dispatch(
            setNotificationState({
              message: "User couldn't be updated. Please try again.",
              type: 'error',
            })
          )
        }
      })
    }
  }

  return (
    <FormContainer>
      <LogoHolder>
        {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
      </LogoHolder>
      <Form id="form" onSubmit={handleSubmit} ref={accSetupForm}>
        <InputBox>
          <Label htmlFor="email">Email</Label>
          <InputField type="email" name="email" id="email" required />
        </InputBox>
        <InputBox>
          <Label htmlFor="Username">Username</Label>
          <InputField
            type="Username"
            name="username"
            id="username"
            ref={username}
            required
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="password">Password</Label>
          <InputField
            type="password"
            name="password"
            id="password"
            ref={pass1}
            required
          />
        </InputBox>
        <InputBox>
          <Label htmlFor="confirmedPass">Confirm Password</Label>
          <InputField
            type="password"
            name="confirmedPass"
            id="confirmedPass"
            ref={pass2}
            required
          />
        </InputBox>
        <SubmitBtn type="submit">Submit</SubmitBtn>
      </Form>
    </FormContainer>
  )
}

export default AccountSetup
