import { DateTime } from 'luxon'
import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  setInvitationSelected,
  setInvitationURL,
} from '../redux/invitationsReducer'

import {
  StyledPopup,
  InputBox,
  ModalMedium,
  ModalHeader,
  ModalContentWrapper,
  ModalContent,
  CloseBtn,
  Actions,
  CancelBtn,
  SubmitBtnModal,
  ModalLabel,
  InputFieldModal,
} from './CommonStylesForms'

const SampleDataLabel = styled.label`
  font-size: 1.5em;
  width: 35%;
  margin-right: 10px;
`

function FormKycIssuance(props) {
  const [kycDetails, setKycDetails] = useState({})
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const schemas = useSelector((state) => state.settings.schemas)
  const connections = contactsState.contactSelected.Connections
  const kycForm = useRef(null)

  const { sendRequest, closeKycModal, kycModalIsOpen } = props

  const handleClosingModal = () => {
    dispatch(setInvitationURL(''))
    dispatch(setInvitationSelected({}))

    closeKycModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = new FormData(kycForm.current)

    const kycAttr = [
      {
        name: 'surnames',
        value: form.get('surnames'),
      },
      {
        name: 'given_names',
        value: form.get('given_names'),
      },
      {
        name: 'date_of_birth',
        value: form.get('date_of_birth') ? Math.floor(
            DateTime.fromISO(form.get('date_of_birth')).ts / 1000
          ).toString() : '',
      },
      {
        name: 'gender_legal',
        value: form.get('gender_legal'),
      },
      {
        name: 'photograph',
        value: form.get('photograph'),
      },
      {
        name: 'email',
        value: form.get('email'),
      },
      {
        name: 'mobile_phone_number',
        value: form.get('mobile_phone_number'),
      },
      {
        name: 'street_address',
        value: form.get('street_address'),
      },
      {
        name: 'city',
        value: form.get('city'),
      },
      {
        name: 'state_province_region',
        value: form.get('state_province_region'),
      },
      {
        name: 'postal_code',
        value: form.get('postal_code'),
      },
      {
        name: 'proof_of_address_verified',
        value: form.get('proof_of_address_verified'),
      },
      {
        name: 'proof_of_address_method',
        value: form.get('proof_of_address_method'),
      },
      {
        name: 'government_id_type',
        value: form.get('government_id_type'),
      },
      {
        name: 'government_id_number',
        value: form.get('government_id_number'),
      },
      {
        name: 'government_id_expiration',
        value: form.get('government_id_expiration'),
      },
      {
        name: 'accounts_verified',
        value: form.get('accounts_verified'),
      },
      {
        name: 'accounts_sum_of_balances_current',
        value: form.get('accounts_sum_of_balances_current'),
      },
      {
        name: 'accounts_sum_of_balances_6_month_average',
        value: form.get('accounts_sum_of_balances_6_month_average'),
      },
      {
        name: 'employment_current_verified',
        value: form.get('employment_current_verified'),
      },
      {
        name: 'employment_current_employer',
        value: form.get('employment_current_employer'),
      },
      {
        name: 'employment_current_start_date',
        value: form.get('employment_current_start_date') ? Math.floor(
            DateTime.fromISO(form.get('employment_current_start_date')).ts / 1000
          ).toString() : '',
      },
      {
        name: 'employment_previous_verified',
        value: form.get('employment_previous_verified'),
      },
      {
        name: 'employment_previous_employer',
        value: form.get('employment_previous_employer'),
      },
      {
        name: 'employment_previous_start_date',
        value: form.get('employment_previous_start_date') ? Math.floor(
            DateTime.fromISO(form.get('employment_previous_start_date')).ts / 1000
          ).toString() : '',
      },
      {
        name: 'income_verified',
        value: form.get('income_verified'),
      },
      {
        name: 'income_annual',
        value: form.get('income_annual'),
      },
      {
        name: 'income_currency',
        value: form.get('income_currency'),
      },
      {
        name: 'credit_score',
        value: form.get('credit_score'),
      },
      {
        name: 'credit_score_agency',
        value: form.get('credit_score_agency'),
      },
      {
        name: 'risk_rating',
        value: form.get('risk_rating'),
      },
      {
        name: 'blacklists_checked',
        value: form.get('blacklists_checked'),
      },
      {
        name: 'sdd_performed',
        value: form.get('sdd_performed'),
      },
      {
        name: 'edd_performed',
        value: form.get('edd_performed'),
      },
      {
        name: 'credential_issued_date',
        value: form.get('credential_issued_date') ? Math.floor(
            DateTime.fromISO(form.get('credential_issued_date')).ts / 1000
          ).toString() : '',
      },
    ]

    const schema = schemas.SCHEMA_KYC
    const schemaParts = schema.split(':')
    
      sendRequest('CREDENTIALS', 'ISSUE_TO_CONNECTIONS', {
        connections: connections,
        schemaID: schema,
        schemaVersion: schemaParts[3],
        schemaName: schemaParts[2],
        schemaIssuerDID: schemaParts[0],
        attributes: kycAttr,
    })

    handleClosingModal()
  }

  const selectOption = (option) => {
    switch (option) {
      case 'option_one':
        setKycDetails({
          surnames: 'Smith',
          given_names: 'John',
          date_of_birth: '1992-12-04',
          gender_legal: 'M',
          photograph: 'N/A',
          email: 'johnsmith@sampledomain.com',
          mobile_phone_number: '801-555-2134',
          street_address: '1890 Fairway Drive',
          city: 'Dallas',
          state_province_region: 'TX',
          postal_code: '74102',
          proof_of_address_verified: 'Yes',
          proof_of_address_method: 'Passport',
          government_id_type: 'Citizen',
          government_id_number: '11224314',
          government_id_expiration: '2020-08-07',
          accounts_verified: 'Yes',
          accounts_sum_of_balances_current: '780000',
          accounts_sum_of_balances_6_month_average: '170000',
          employment_current_verified: 'Yes',
          employment_current_employer: 'Apple Inc.',
          employment_current_start_date: '2020-10-05',
          employment_previous_verified: 'Yes',
          employment_previous_employer: 'Qualtrics',
          employment_previous_start_date: '2018-04-29',
          income_verified: 'Yes',
          income_annual: '199000',
          income_currency: 'USD',
          credit_score: '792',
          credit_score_agency: 'Equifax',
          risk_rating: '4',
          blacklists_checked: 'Yes',
          sdd_performed: 'Yes',
          edd_performed: 'Yes',
          credential_issued_date: '2021-09-07',
        })

        break

      case 'option_two':
        setKycDetails({
          surnames: 'Connor',
          given_names: 'Sarah',
          date_of_birth: '1994-02-03',
          gender_legal: 'F',
          photograph: 'N/A',
          email: 'sarahconnor@sampledomain.com',
          mobile_phone_number: '801-772-9192',
          street_address: '2000W 400S',
          city: 'Salt Lake City',
          state_province_region: 'UT',
          postal_code: '84341',
          proof_of_address_verified: 'Yes',
          proof_of_address_method: 'Government',
          government_id_type: 'Drivers License',
          government_id_number: '91824',
          government_id_expiration: '2030-08-25',
          accounts_verified: 'Yes',
          accounts_sum_of_balances_current: '10000',
          accounts_sum_of_balances_6_month_average: '12000',
          employment_current_employer: 'Meta',
          employment_current_start_date: '2022-12-08',
          employment_previous_verified: 'Yes',
          employment_previous_employer: 'Amazon',
          employment_previous_start_date: '2016-10-03',
          income_verified: 'Yes',
          income_annual: '80000',
          income_currency: 'USD',
          credit_score: '650',
          credit_score_agency: 'Experian',
          risk_rating: 'Good',
          blacklists_checked: 'Yes',
          sdd_performed: 'No',
          edd_performed: 'Yes',
          credential_issued_date: '2020-07-14',
        })
  
          break

      case 'option_three':
        setKycDetails({
          surnames: 'Johnson',
          given_names: 'Landon',
          date_of_birth: '1985-04-27',
          gender_legal: 'M',
          photograph: 'N/A',
          email: 'landonjohnson@sampledomain.com',
          mobile_phone_number: '801-777-8290',
          street_address: '334 John Stockton Drive',
          city: 'Orlando',
          state_province_region: 'FL',
          postal_code: '62810',
          proof_of_address_verified: 'Yes',
          proof_of_address_method: 'Documents',
          government_id_type: 'Passport',
          government_id_number: '830123',
          government_id_expiration: '2028-09-14',
          accounts_verified: 'Yes',
          accounts_sum_of_balances_current: '5000',
          accounts_sum_of_balances_6_month_average: '4000',
          employment_current_verified: 'Yes',
          employment_current_employer: 'Walmart',
          employment_current_start_date: '2022-10-12',
          employment_previous_verified: 'Yes',
          employment_previous_employer: 'Target',
          employment_previous_start_date: '2015-07-09',
          income_verified: 'Yes',
          income_annual: '64000',
          income_currency: 'USD',
          credit_score: '782',
          credit_score_agency: 'TransUnion',
          risk_rating: 'Good',
          blacklists_checked: 'Yes',
          sdd_performed: 'Yes',
          edd_performed: 'No',
          credential_issued_date: '2023-01-18',
        })

        break
    
      

      default:
        setKycDetails({
          surnames: '',
          given_names: '',
          date_of_birth: '',
          gender_legal: '',
          photograph: '',
          email: '',
          mobile_phone_number: '',
          street_address: '',
          city: '',
          state_province_region: '',
          postal_code: '',
          proof_of_address_verified: '',
          proof_of_address_method: '',
          government_id_type: '',
          government_id_number: '',
          government_id_expiration: '',
          accounts_verified: '',
          accounts_sum_of_balances_current: '',
          accounts_sum_of_balances_6_month_average: '',
          employment_current_verified: '',
          employment_current_employer: '',
          employment_current_start_date: '',
          employment_previous_verified: '',
          employment_previous_employer: '',
          employment_previous_start_date: '',
          income_verified: '',
          income_annual: '',
          income_currency: '',
          credit_score: '',
          credit_score_agency: '',
          risk_rating: '',
          blacklists_checked: '',
          sdd_performed: '',
          edd_performed: '',
          credential_issued_date: '',
        })
        break
    }
  }

  return (
    <StyledPopup
      open={kycModalIsOpen}
      closeOnDocumentClick
      onClose={closeKycModal}
    >
      <ModalMedium className="modal">
        <ModalHeader>Issue KYC Credential</ModalHeader>
        <ModalContentWrapper>
            <ModalContent>
              <InputBox style={{ marginBottom: '25px' }}>
                <SampleDataLabel>KYC Credential Sample Sets</SampleDataLabel>
                <select
                  onChange={(e) => selectOption(e.target.value)}
                  className="dropdown-content"
                >
                  <option value="default">Select Option:</option>
                  <option value="option_one">Option 1</option>
                  <option value="option_two">Option 2</option>
                  <option value="option_three">Option 3</option>
                </select>
            </InputBox>
              <form onSubmit={handleSubmit} ref={kycForm}>
                <InputBox>
                  <ModalLabel htmlFor="surnames">
                    Surname
                  </ModalLabel>
                  <InputFieldModal
                    name="surnames"
                    id="surnames"
                    placeholder=""
                    defaultValue={kycDetails.surnames}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="given_names">
                    Given Names
                  </ModalLabel>
                  <InputFieldModal
                    name="given_names"
                    id="given_names"
                    placeholder=""
                    defaultValue={kycDetails.given_names}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="date_of_birth">
                    Date of Birth
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="date_of_birth"
                    id="date_of_birth"
                    placeholder=""
                    defaultValue={kycDetails.date_of_birth}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="gender_legal">
                    Gender
                  </ModalLabel>
                  <InputFieldModal
                    name="gender_legal"
                    id="gender_legal"
                    placeholder=""
                    defaultValue={kycDetails.gender_legal}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="photograph">
                    Photograph
                  </ModalLabel>
                  <InputFieldModal
                    name="photograph"
                    id="photograph"
                    placeholder=""
                    defaultValue={kycDetails.photograph}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="email">
                    Email
                  </ModalLabel>
                  <InputFieldModal
                    name="email"
                    id="email"
                    placeholder=""
                    defaultValue={kycDetails.email}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="mobile_phone_number">
                    Mobile Phone Number
                  </ModalLabel>
                  <InputFieldModal
                    name="mobile_phone_number"
                    id="mobile_phone_number"
                    placeholder=""
                    defaultValue={kycDetails.mobile_phone_number}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="street_address">
                    Street Address
                  </ModalLabel>
                  <InputFieldModal
                    name="street_address"
                    id="street_address"
                    placeholder=""
                    defaultValue={kycDetails.street_address}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="city">
                    City
                  </ModalLabel>
                  <InputFieldModal
                    name="city"
                    id="city"
                    placeholder=""
                    defaultValue={kycDetails.city}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="state_province_region">
                    State, Province, Region
                  </ModalLabel>
                  <InputFieldModal
                    name="state_province_region"
                    id="state_province_region"
                    placeholder=""
                    defaultValue={kycDetails.state_province_region}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="postal_code">
                    Postal Code
                  </ModalLabel>
                  <InputFieldModal
                    name="postal_code"
                    id="postal_code"
                    placeholder=""
                    defaultValue={kycDetails.postal_code}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="proof_of_address_verified">
                    Proof of Address
                  </ModalLabel>
                  <InputFieldModal
                    name="proof_of_address_verified"
                    id="proof_of_address_verified"
                    placeholder=""
                    defaultValue={kycDetails.proof_of_address_verified}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="proof_of_address_method">
                    Proof of Address Method
                  </ModalLabel>
                  <InputFieldModal
                    name="proof_of_address_method"
                    id="proof_of_address_method"
                    placeholder=""
                    defaultValue={kycDetails.proof_of_address_method}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="government_id_type">
                    ID Type
                  </ModalLabel>
                  <InputFieldModal
                    name="government_id_type"
                    id="government_id_type"
                    placeholder=""
                    defaultValue={kycDetails.government_id_type}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="government_id_number">
                    ID Number
                  </ModalLabel>
                  <InputFieldModal
                    name="government_id_number"
                    id="government_id_number"
                    placeholder=""
                    defaultValue={kycDetails.government_id_number}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="government_id_expiration">
                    ID Expiration
                  </ModalLabel>
                  <InputFieldModal
                    name="government_id_expiration"
                    id="government_id_expiration"
                    placeholder=""
                    defaultValue={kycDetails.government_id_expiration}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="accounts_verified">
                    Accounts Verified
                  </ModalLabel>
                  <InputFieldModal
                    name="accounts_verified"
                    id="accounts_verified"
                    placeholder=""
                    defaultValue={kycDetails.accounts_verified}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="accounts_sum_of_balances_current">
                    Current Sum of Balances
                  </ModalLabel>
                  <InputFieldModal
                    name="accounts_sum_of_balances_current"
                    id="accounts_sum_of_balances_current"
                    placeholder=""
                    defaultValue={kycDetails.accounts_sum_of_balances_current}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="accounts_sum_of_balances_6_month_average">
                    Sum of Balances - 6 Month Average
                  </ModalLabel>
                  <InputFieldModal
                    name="accounts_sum_of_balances_6_month_average"
                    id="accounts_sum_of_balances_6_month_average"
                    placeholder=""
                    defaultValue={kycDetails.accounts_sum_of_balances_6_month_average}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_current_verified">
                    Current Employment Verified
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_current_verified"
                    id="employment_current_verified"
                    placeholder=""
                    defaultValue={kycDetails.employment_current_verified}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_current_employer">
                    Current Employer
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_current_employer"
                    id="employment_current_employer"
                    placeholder=""
                    defaultValue={kycDetails.employment_current_employer}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_current_start_date">
                    Current Start Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="employment_current_start_date"
                    id="employment_current_start_date"
                    placeholder=""
                    defaultValue={kycDetails.employment_current_start_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_previous_verified">
                    Previous Employment Verified
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_previous_verified"
                    id="employment_previous_verified"
                    placeholder=""
                    defaultValue={kycDetails.employment_previous_verified}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_previous_employer">
                    Previous Employer
                  </ModalLabel>
                  <InputFieldModal
                    name="employment_previous_employer"
                    id="employment_previous_employer"
                    placeholder=""
                    defaultValue={kycDetails.employment_previous_employer}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="employment_previous_start_date">
                    Previous Employment Start Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="employment_previous_start_date"
                    id="employment_previous_start_date"
                    placeholder=""
                    defaultValue={kycDetails.employment_previous_start_date}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="income_verified">
                    Income Verified
                  </ModalLabel>
                  <InputFieldModal
                    name="income_verified"
                    id="income_verified"
                    placeholder=""
                    defaultValue={kycDetails.income_verified}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="income_annual">
                    Annual Income
                  </ModalLabel>
                  <InputFieldModal
                    name="income_annual"
                    id="income_annual"
                    placeholder=""
                    defaultValue={kycDetails.income_annual}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="income_currency">
                    Currency
                  </ModalLabel>
                  <InputFieldModal
                    name="income_currency"
                    id="income_currency"
                    placeholder=""
                    defaultValue={kycDetails.income_currency}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="credit_score">
                    Credit Score
                  </ModalLabel>
                  <InputFieldModal
                    name="credit_score"
                    id="credit_score"
                    placeholder=""
                    defaultValue={kycDetails.credit_score}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="credit_score_agency">
                    Credit Score Agency
                  </ModalLabel>
                  <InputFieldModal
                      name="credit_score_agency"
                    id="credit_score_agency"
                    placeholder=""
                    defaultValue={kycDetails.credit_score_agency}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="risk_rating">
                    Risk Rating
                  </ModalLabel>
                  <InputFieldModal
                    name="risk_rating"
                    id="risk_rating"
                    placeholder=""
                    defaultValue={kycDetails.risk_rating}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="blacklists_checked">
                    Blacklists Checked
                  </ModalLabel>
                  <InputFieldModal
                    name="blacklists_checked"
                    id="blacklists_checked"
                    placeholder=""
                    defaultValue={kycDetails.blacklists_checked}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="sdd_performed">
                    Sdd Performed
                  </ModalLabel>
                  <InputFieldModal
                    name="sdd_performed"
                    id="sdd_performed"
                    placeholder=""
                    defaultValue={kycDetails.sdd_performed}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="edd_performed">
                    Edd Performed
                  </ModalLabel>
                  <InputFieldModal
                    name="edd_performed"
                    id="edd_performed"
                    placeholder=""
                    defaultValue={kycDetails.edd_performed}
                  />
                </InputBox>
                <InputBox>
                  <ModalLabel htmlFor="credential_issued_date">
                    Credential Issued Date
                  </ModalLabel>
                  <InputFieldModal
                    type="date"
                    name="credential_issued_date"
                    id="credential_issued_date"
                    placeholder=""
                    defaultValue={kycDetails.credential_issued_date}
                  />
                </InputBox>
                <Actions>
                  <CancelBtn type="button" onClick={handleClosingModal}>
                    Cancel
                  </CancelBtn>
                  <SubmitBtnModal type="submit">Send</SubmitBtnModal>
                </Actions>
              </form>
            </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={handleClosingModal}>&times;</CloseBtn>
      </ModalMedium>
    </StyledPopup>
  )
}

export default FormKycIssuance

