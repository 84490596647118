import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { setNotificationState } from '../redux/notificationsReducer'

import { FormContainer, LogoHolder, Logo, QRHolder } from './CommonStylesForms'
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 10px;
  width: 300px;
`
function EmailVerification(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)
  const logo = settingsState.logo

  const [QRCodeURL, setQRCodeURL] = useState(false)

  const token = window.location.hash.substring(1)
  useEffect(() => {
    // Kick the user off this page if trying to access without a token
    if (!token) {
      console.log('No token')
      return
    }

    const decoded = jwt_decode(token)
    // Check if the token is expired
    if (Date.now() >= decoded.exp * 1000) {
      console.log('This link has expired')
      dispatch(
        setNotificationState({
          message: 'This link has expired',
          type: 'error',
        })
      )
      props.history.push('/admin/login')
    } else {
      console.log('The token is valid')
    }

    // Check the token on the back end
    Axios({
      method: 'POST',
      data: {
        token: token,
      },
      url: '/api/email/invite',
    }).then((res) => {
      if (res.data.error) {
        dispatch(
          setNotificationState({ message: res.data.error, type: 'error' })
        )
        props.history.push('/admin/login')
      } else {
        setQRCodeURL(res.data.invitation_url)
      }
    })
  }, [])

  return (
    <FormContainer>
      <LogoHolder>
        {logo ? <Logo src={logo} alt="Logo" /> : <Logo />}
      </LogoHolder>
      <p>Scan the QR code to get your email credential!</p>
      <QRHolder>
        {QRCodeURL ? (
          <QR value={QRCodeURL} size={256} renderAs="svg" />
        ) : (
          <p>Loading...</p>
        )}
      </QRHolder>
    </FormContainer>
  )
}

export default EmailVerification
